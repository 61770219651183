




































import Vue from "vue";
import "vue-toast-notification/dist/theme-sugar.css";
import Component from "vue-class-component";
import UserMenu from "./components/UserMenu.vue";
import AddContent from "./components/AddContent.vue";
import auth from "@/store/modules/user";
import QuickSearch from "@/components/QuickSearch.vue";
import Notificatons from "@/components/Notifications.vue";
import groupModule from "@/store/modules/groups";
import { Watch } from "vue-property-decorator";
import NprogressContainer from "@/components/utility/NprogressContainer.vue";
import FooterApp from "@/views/Footer.vue";

@Component({
  components: {
    UserMenu,
    AddContent,
    QuickSearch,
    Notificatons,
    NprogressContainer,
    FooterApp,
  },
})
export default class App extends Vue {
  loaded = false;

  get isLoggedIn(): boolean {
    return auth.isLoggedIn;
  }

  created(): void {
    this.init();
  }

  async init(): Promise<void> {
    if (this.isLoggedIn && !this.loaded) {
      await groupModule.loadGroups();
    }
    this.loaded = true;
  }

  @Watch("isLoggedIn")
  logInChange(): void {
    this.init();
  }
}
