









































import { Component, Vue } from "vue-property-decorator";
import userModule from "@/store/modules/user";
import Notification from "@/types/notification";
import NotificationItem from "@/components/notification/NotificationItem.vue";

@Component({
  components: {
    NotificationItem,
  },
})
export default class Notifications extends Vue {
  converter = { Task: "taskDisplay" };

  get items(): Notification[] {
    return userModule.notifications;
  }

  get hasItem(): boolean {
    if (!this.items) return false;
    return this.items.length > 0;
  }

  converterTo(name: string): string {
    return name in this.converter ? this.converter[name] : name;
  }

  markAsRead(notif: Notification): void {
    userModule.markAsRead([notif]);
  }

  markAllAsRead(): void {
    userModule.markAsRead(this.items);
  }

  mounted(): void {
    //first time load all
    userModule.loadNotifications();
  }
}
