

























































import { Vue, Component } from "vue-property-decorator";
import user from "@/store/modules/user";
import { Dictionary } from "@/types/helpers";

@Component
export default class FooterApp extends Vue {
  links = [
    {
      href: "https://github.com/HE-Arc/Caravel",
      icon: "mdi-github",
      auth: false,
    },
    {
      href: "https://discord.gg/GMMSaKWwZU",
      icon: "mdi-discord",
      auth: true,
    },
    {
      href: "/getstarted",
      icon: "mdi-book",
      auth: false,
    },
  ];

  get linksFiltered(): Array<Dictionary<string | boolean>> {
    return this.links.filter((link) => !link.auth || this.isLoggedIn);
  }
  get isLoggedIn(): boolean {
    return user.isLoggedIn;
  }
}
