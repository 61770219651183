


















import { Component, Vue } from "vue-property-decorator";
@Component
export default class QuickSearch extends Vue {
  text = "";

  search(): void {
    this.$router.push({ name: "GroupSearch", query: { text: this.text } });
    this.text = "";
  }
}
