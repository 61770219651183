













































import { Prop, Component, Vue } from "vue-property-decorator";
import auth from "@/store/modules/user";
import { User } from "@/types/user";

@Component
export default class UserMenu extends Vue {
  @Prop({ default: false }) isTitleHidden?: boolean;
  @Prop({ default: false }) hasDropDown?: boolean;

  get user(): User | undefined {
    return auth.user;
  }

  get hasUser(): boolean {
    return !!this.user;
  }

  async logout(): Promise<void> {
    try {
      await auth.logout();
      this.$router.push({ name: "Login" });
      this.$toast.success(this.$t("login.logout").toString());
    } catch {
      this.$toast.error(this.$t("login.failed").toString());
    }
  }
}
