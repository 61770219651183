





















import { Component, Vue, Prop } from "vue-property-decorator";
import userModule from "@/store/modules/user";
import Notification from "@/types/notification";

@Component
export default class NotificationItem extends Vue {
  @Prop() item!: Notification;
  converter = { Task: "taskDisplay" };

  converterTo(name: string): string {
    return name in this.converter ? this.converter[name] : name;
  }

  markAsRead(): void {
    userModule.markAsRead([this.item]);
  }

  get href(): string | undefined {
    if (this.item.data.type == 3) return undefined;
    return this.$router.resolve({
      name: this.converterTo(this.item.data.model),
      params: {
        group_id: this.item.data.group_id,
        task_id: this.item.data.model_id,
      },
    }).href;
  }
}
