





























import { Component, Vue } from "vue-property-decorator";
import { Dictionary } from "@/types/helpers";

@Component
export default class AddContent extends Vue {
  get items(): Dictionary<string | boolean>[] {
    return [
      {
        key: "group",
        to: "GroupSearch",
        needGroup: false,
      },
      {
        key: "task",
        to: "newTask",
        needGroup: true,
      },
      {
        key: "subject",
        to: "subjects",
        needGroup: true,
      },
    ];
  }

  get hasGroupRoute(): boolean {
    return this.$route.params.group_id != undefined;
  }
}
